import "core-js/modules/es.array.push.js";
// import routes from '../router/routes'
import { Dialog, showConfirmDialog, showToast } from "vant";
export default {
  name: "index",
  data() {
    return {
      showAll: false,
      showPopover: false,
      showAction: false,
      actions: [{
        text: "选项一"
      }, {
        text: "选项二"
      }, {
        text: "选项三"
      }],
      activeNames: ["1"],
      studentInfo: {
        idCard: "000000000000000000"
      },
      myplans: [{
        id: 1,
        name: "暂无方案"
      }],
      sex: ["未知", "男", "女"],
      planTemp: {},
      state: ''
    };
  },
  methods: {
    del() {
      var that = this;
      showConfirmDialog({
        title: '警告！',
        message: '你确定要删除么？',
        theme: 'round-button'
      }).then(() => {
        // on confirm
        that.$http.get('/api/old/delStu', {
          id: that.studentInfo.id
        }, '正在提交数据').then(result => {
          console.log(result);
          if (result.success) {
            this.$router.push('/old/myinfos');
          } else {
            showToast(result.msg);
          }
        });
      }).catch(() => {
        showToast('用户取消');
        // on cancel
      });
    },

    onClickLeft() {
      this.$router.push({
        path: '/old/index'
      });
    },
    onSelect(action) {
      console.log(action);
      var that = this;
      this.planTemp = action;
      this.showAction = false;
      // that.$toast(action.name);
    },

    getmytask() {
      var that = this;
      that.$http.get("/api/old/getMyPlans", {
        idCard: that.studentInfo.idCard
      }, "正在检索量表，请稍后").then(result => {
        console.log(result);
        if (result.success) {
          console.log(result.data);
          if (result.data.length > 0) {
            that.myplans = result.data;
            console.log(that.myplans);
            that.planTemp = that.myplans[0];
            that.myplans.forEach(plan => {
              console.log(plan);
              plan.scaleInfos.forEach(scale => {
                console.log(scale);
                if (scale.states < 1) {}
              });
            });
          }
        } else {
          if (result.msg == "请先签名") {
            that.$router.push("/sign");
          } else {
            that.$toast(result.msg);
          }
          //this.showQYWXCode();
        }
      });
    },

    reTest(scale) {
      //验证是否可以复测，然后直接跳转，不带id
      var that = this;
      that.$http.get('/api/old/reTest/' + that.planTemp.id + '/' + scale.id, null, '正在加载量表内容，请耐心等待').then(result => {
        console.log(result);
        if (result.success) {
          showConfirmDialog({
            title: '请确认！',
            message: '你是点错了，还是真的要再次测试？',
            theme: 'round-button'
          }).then(() => {
            // on confirm
            this.$router.push({
              path: "./testDo",
              query: {
                scaleId: scale.id,
                planId: this.planTemp.id
              }
            });
          }).catch(() => {
            // on cancel
          });
        } else {
          showToast({
            icon: 'clear',
            title: "提示",
            message: "真没必要！",
            duration: 5000
          });
        }
      });
      // this.$router.push({
      //   path: "./testDo",
      //   query: { scaleId: scale.id, planId: this.planTemp.id, id: scale.remark },
      // });
    },
    goTest(scale) {
      this.$router.push({
        path: "./testDo",
        query: {
          scaleId: scale.id,
          planId: this.planTemp.id,
          id: scale.remark
        }
      });
    }
  },
  created() {},
  mounted() {
    this.studentInfo = JSON.parse(localStorage.getItem("stu"));
    var userstr = localStorage.getItem("user");
    var user = JSON.parse(userstr);
    this.getmytask();
  }
};